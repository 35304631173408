import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Component Imports
import { HeroPreHeader, D1, P1 } from '../theme/typography';
import Button from './buttons';
import VideoModal from './videoModal';

// Styled Components
const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 650px;
  margin: 0 auto;
`;

const SecondaryHeader = styled(HeroPreHeader)`
  margin-bottom: 4px;
  color: var(--secondary-text);
`;

const PrimaryHeader = styled(D1)`
  margin-bottom: 24px;
  text-transform: capitalize;
  color: var(--primary-text);
`;

const BodyText = styled(P1)`
  margin-bottom: ${({ hasButtons }) => (hasButtons ? '32px' : '0')};
  color: var(--primary-text);
  text-wrap: balance;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  width: 100%;
  @media (min-width: 500px) {
    flex-direction: row;
    width: unset;
  }
`;

const CenteredHeaderBlock = ({ data, ...props }) => {
  const {
    preHeader,
    title,
    body,
    primaryCTA,
    primaryCTAData,
    secondaryCTA,
    secondaryCTAData,
  } = data;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = useCallback(
    () => setIsModalOpen((prevState) => !prevState),
    [],
  );

  return (
    <BlockContainer {...props}>
      <SecondaryHeader>{preHeader}</SecondaryHeader>
      <PrimaryHeader>{title}</PrimaryHeader>
      <BodyText hasButtons={primaryCTA || secondaryCTA}>{body}</BodyText>
      <ButtonContainer>
        {primaryCTA && (
          <Button
            label={primaryCTAData.label}
            type={primaryCTAData.type ? primaryCTAData.type : 'primary'}
            arrow={primaryCTAData.arrow ? true : false}
            path={primaryCTAData.path ? primaryCTAData.path : ''}
          />
        )}
        {secondaryCTA && !secondaryCTAData.videoModal && (
          <Button
            label={secondaryCTAData.label}
            type={secondaryCTAData.type ? secondaryCTAData.type : 'link'}
            arrow={secondaryCTAData.arrow ? true : false}
            path={secondaryCTAData.path ? secondaryCTAData.path : ''}
          />
        )}
        {secondaryCTA && secondaryCTAData.videoModal && (
          <>
            <Button
              label={secondaryCTAData.label}
              type={secondaryCTAData.type ? secondaryCTAData.type : 'link'}
              arrow={secondaryCTAData.arrow ? true : false}
              onClick={toggleModal}
            />
            <VideoModal
              isModalOpen={isModalOpen}
              video={secondaryCTAData.video}
              onClose={() => setIsModalOpen(false)}
            />
          </>
        )}
      </ButtonContainer>
    </BlockContainer>
  );
};

CenteredHeaderBlock.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    preHeader: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    primaryCTA: PropTypes.bool,
    primaryCTAData: PropTypes.object,
    secondaryCTA: PropTypes.bool,
    secondaryCTAData: PropTypes.object,
  }),
};

export default CenteredHeaderBlock;
