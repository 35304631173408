import React from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StripeContainer = styled.div`
  > svg > path {
    will-change: transform;
    transition: transform 0.5s ease-in-out, opacity 0.2s ease-in-out;
    transform: ${({ inView, direction, theme }) =>
      inView ? `none` : theme.stripeMovement[direction]};

    opacity: ${({ inView, midPage }) => (!inView && midPage ? 0 : 1)};

    :first-child {
      transition-delay: 0.1s;
    }
    :nth-child(3) {
      transition-delay: 0.2s;
    }
    :only-child {
      transition-delay: 0s;
    }
  }

  @media (prefers-reduced-motion: reduce) {
    > svg {
      transform: none;
    }
  }
`;

const AnimatedStripe = ({
  threshold = 0.15,
  triggerOnce = false,
  direction = 'right',
  midPage = false,
  ...remainingProps
}) => {
  const [ref, inView] = useInView({ threshold, triggerOnce });

  return (
    <StripeContainer
      ref={ref}
      inView={inView}
      direction={direction}
      midPage={midPage}
      {...remainingProps}
    />
  );
};

AnimatedStripe.propTypes = {
  threshold: PropTypes.number,
  triggerOnce: PropTypes.bool,
  direction: PropTypes.string,
  midPage: PropTypes.bool,
};

export default AnimatedStripe;
