import React from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';

const IntContainer = styled.span`
  @property --num {
    syntax: '<integer>';
    initial-value: ${({ startNum }) => (startNum ? startNum : 0)};
    inherits: false;
  }

  transition: --num 2s cubic-bezier(0.215, 0.61, 0.355, 1);

  //Set content to final desired result for browsers that don't support counter-set
  ${({ endNum }) => `::after { content: '${endNum}'}`}

  @supports (counter-set: num var(--num)) {
    counter-set: num var(--num);

    ::after {
      content: counter(num);
    }
  }

  ${({ inView, endNum }) => (inView ? `--num: ${endNum};` : '')}
`;

const AnimatedInteger = ({
  startNum = 0,
  endNum,
  triggerOnce = true,
  threshold = 0.15,
  ...remainingProps
}) => {
  const [ref, inView] = useInView({ threshold, triggerOnce });

  return (
    <IntContainer
      ref={ref}
      inView={inView}
      startNum={startNum}
      endNum={endNum}
      {...remainingProps}
    />
  );
};

AnimatedInteger.propTypes = {
  startNum: PropTypes.number,
  endNum: PropTypes.number,
};

export default AnimatedInteger;
