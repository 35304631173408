import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

//Component Imports
import { P1, D2, PreHeader } from '../theme/typography';
import Button from '../components/buttons';
import SubPoint from './_subPoint';

//Asset Imports

//Styled Components
const TextBlock = styled.div`
  color: var(--primary-text);
  width: 100%;
  /* max-width: ${({ type }) => (type === 'simple' ? '' : '435px')}; */

  @media (min-width: 1200px) {
    max-width: unset;
  }
`;

const SecondaryHeader = styled(PreHeader)`
  margin-bottom: 4px;
  color: var(--secondary-text);
`;

const MainBlockWrapper = styled.div`
  max-width: 522px;
  margin-bottom: ${({ type }) => (type === 'simple' ? '48px' : '64px')};

  @media (min-width: 1100px) {
    width: 40%;
  }
  @media (min-width: 1400px) {
    width: unset;
  }
`;

const PrimaryHeader = styled(D2)`
  margin-bottom: ${({ hasContentBelow }) => (hasContentBelow ? '16px' : '0px')};
  text-transform: capitalize;
  color: var(--primary-text);
  text-wrap: balance;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${({ type }) =>
    type === 'primary' ? 'flex-start' : 'space-between'};
  row-gap: 32px;
  column-gap: 32px;
  justify-content: space-between;
  @media (min-width: 1441px) {
    gap: 112px;
    justify-content: flex-start;
  }
`;

const BodyText = styled(P1)`
  margin-bottom: 32px;
  color: var(--primary-text);

  @media (min-width: 1400px) {
    margin-bottom: 48px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  margin: auto 0;
  flex-wrap: wrap;
  text-align: center;

  @media (min-width: 500px) {
    flex-direction: row;
    width: unset;
    text-align: unset;
  }
`;

const TwoPointContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  @media (min-width: 500px) {
    flex-direction: row;
    gap: 56px;
  }

  @media (min-width: 1400px) {
    gap: 96px;
  }
`;

const ButtonBlock = ({
  primaryCTA,
  primaryCTAData,
  secondaryCTA,
  secondaryCTAData,
  darkBG,
}) => (
  <ButtonContainer>
    {primaryCTA && (
      <Button
        label={primaryCTAData.label}
        type={primaryCTAData.type ? primaryCTAData.type : 'primary'}
        arrow={primaryCTAData.arrow ? true : false}
        path={primaryCTAData.path ? primaryCTAData.path : '/'}
        state={primaryCTAData.state && primaryCTAData.state}
      />
    )}
    {secondaryCTA && (
      <Button
        label={secondaryCTAData.label}
        type={secondaryCTAData.type ? secondaryCTAData.type : 'link'}
        arrow={secondaryCTAData.arrow ? true : false}
        path={secondaryCTAData.path ? secondaryCTAData.path : '/'}
        style={{
          color: darkBG ? 'white' : '',
        }}
      />
    )}
  </ButtonContainer>
);

const CTABlock = ({ data }) => {
  const {
    type,
    darkBG,
    title,
    preHeader,
    body,
    primaryCTA,
    primaryCTAData,
    secondaryCTA,
    secondaryCTAData,
    subPoint1,
    subPoint2,
  } = data;

  // console.log(primaryCTAData);

  return (
    <TextBlock type={type} dark={darkBG ? 1 : 0}>
      <SecondaryHeader dark={darkBG ? 1 : 0}>{preHeader}</SecondaryHeader>
      <Row type={type}>
        <MainBlockWrapper type={type}>
          <PrimaryHeader
            dark={darkBG ? 1 : 0}
            hasContentBelow={type === 'primary'}
          >
            {title}
          </PrimaryHeader>

          {type === 'primary' && (
            <BodyText dark={darkBG ? 1 : 0}>{body}</BodyText>
          )}
          {type === 'primary' && (
            <ButtonBlock
              primaryCTA={primaryCTA}
              primaryCTAData={primaryCTAData}
              secondaryCTA={secondaryCTA}
              secondaryCTAData={secondaryCTAData}
              darkBG={darkBG}
            />
          )}
        </MainBlockWrapper>
        {
          {
            primary: (
              <TwoPointContainer>
                <SubPoint data={subPoint1 && subPoint1} />
                <SubPoint data={subPoint2 && subPoint2} />
              </TwoPointContainer>
            ),
            simple: (
              <ButtonBlock
                primaryCTA={primaryCTA}
                primaryCTAData={primaryCTAData}
                secondaryCTA={secondaryCTA}
                secondaryCTAData={secondaryCTAData}
                darkBG={darkBG}
              />
            ),
          }[type]
        }
      </Row>
    </TextBlock>
  );
};

CTABlock.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.oneOf(['primary', 'simple']),
    darkBG: PropTypes.bool,
    title: PropTypes.string.isRequired,
    preHeader: PropTypes.string.isRequired,
    body: PropTypes.string,
    ctaButton: PropTypes.string,
    ctaButtonType: PropTypes.string,
    ctaLink: PropTypes.bool,
  }),
};

CTABlock.defaultProps = {
  data: {
    type: 'primary',
    darkBG: false,
    ctaButton: false,
    ctaLink: false,
  },
};

export default CTABlock;
