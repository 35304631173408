import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Component Imports
import { ButtonText, D1, D3, P2, Technical } from '../theme/typography';
import Button from './buttons';
import { GatsbyImage } from 'gatsby-plugin-image';
import VideoModal from '../components/videoModal';

//Asset imports
import PlaySVG from '../images/icons/playIcon.inline.svg';

//Function Imports
import AnimatedInteger from './animatedInteger';

//Styled Components
const TextContainer = styled.div`
  max-width: 207px;
  position: relative;
  z-index: 2;
`;

const TextTitle = styled(D3)`
  margin-bottom: 16px;
`;

const TextBody = styled(P2)`
  margin-bottom: 16px;
`;

const StatContainer = styled.div`
  // This realigns the D1 to the top of its line height so that it lines up with block next to it.
  position: relative;
  top: -10px;
  max-width: 207px;
`;

const Stat = styled(D1)`
  color: var(--secondary-text);
`;

const StatDesc = styled(ButtonText)``;

const PosterImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s ease;
`;

const VideoContainer = styled.div`
  min-width: 207px;
  max-width: 207px;
  cursor: pointer;
  &:hover {
    ${PosterImage} {
      transform: scale(1.1);
    }
  }
`;

const VideoThumb = styled.div`
  margin-bottom: 4px;
  background: ${({ theme }) => theme.color.blue2};
  border-radius: 4px;
  overflow: hidden;
`;

const AspectRatio = styled.div`
  height: 0;
  padding-top: 56.25%;
  position: relative;
`;

const AspectRatioContents = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const GradientDiv = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(8, 39, 58, 0.9) 0%,
    rgba(255, 255, 255, 0) 50%
  );
`;

const PlayButton = styled(PlaySVG)`
  stroke: white;
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: ${({ theme }) => theme.spacing.xs};
  right: ${({ theme }) => theme.spacing.xs};

  @media (min-width: 27em) {
    right: ${({ theme }) => theme.spacing.xs};
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const VideoTitle = styled(ButtonText)``;

const Duration = styled(Technical)`
  color: ${({ theme }) => theme.color.accent};
`;

const ModalWrapper = styled.div`
  /* Take it out of document flow */
  position: absolute;
  /* Just to overwrite the modal video plugins styles */
  .modal-video-movie-wrap {
    background: none;
  }

  .modal-video {
    background-color: rgba(0, 0, 0, 0.9);
  }

  .modal-video-close-btn {
    cursor: pointer;
  }
`;

const ContactCard = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 240px;
`;

const HeadshotWrap = styled.div`
  position: relative;
  width: 88px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  overflow: clip;
  margin-bottom: 16px;
`;

const Headshot = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Name = styled(D3)`
  color: var(--secondary-text);
  text-transform: uppercase;
`;

const Title = styled(ButtonText)`
  color: var(--accent);
  text-transform: uppercase;
  margin-bottom: 16px;
`;

const ContactDetailWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  /* border: 1px solid blue; */
  transition: opacity 0.25s ease;

  &:hover {
    opacity: 0.5;
  }
`;

const ContactIcon = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  transform: translateY(-1px);

  svg {
    stroke: var(--accent);
  }
`;

const ContactDetail = styled(P2)``;

const splitStatistic = (statistic) => {
  if (!statistic) {
    return null;
  }

  let brokenStatArray = statistic.split(/[0-9]+/);

  if (brokenStatArray.length > 3) {
    console.log('Statistic Split Error');
    console.log(`Original Statistic: ${statistic}`);
    console.log(brokenStatArray);
  }

  return {
    pre: brokenStatArray[0],
    integer: parseInt(statistic.match(/[0-9]+/)),
    post: brokenStatArray[brokenStatArray.length - 1],
  };
};

const SubPoint = ({ data }) => {
  const {
    content,
    title,
    body,
    link,
    videoTitle,
    video,
    tempVideo,
    poster,
    duration,
    statistic,
    description,
    employee,
  } = data;

  const [isModalOpen, setIsModalOpen] = useState(false);

  let brokenStatistic;
  if (content == 'statistic') {
    brokenStatistic = splitStatistic(statistic);
  }

  return (
    <>
      {
        {
          text: (
            <TextContainer>
              <TextTitle>{title}</TextTitle>
              <TextBody>{body}</TextBody>
              {link && link.label && (
                <Button
                  label={link && link.label ? link.label : 'Contact'}
                  type={link && link.type ? link.type : 'link'}
                  arrow={link && link.arrow ? true : false}
                  path={link && link.path ? link.path : '/'}
                />
              )}
            </TextContainer>
          ),
          video: (
            <>
              <VideoContainer onClick={() => setIsModalOpen(true)}>
                <VideoThumb>
                  <AspectRatio>
                    <AspectRatioContents>
                      <PosterImage
                        image={poster && poster.asset.gatsbyImageData}
                        alt='Video Poster'
                      />
                      <GradientDiv />
                      <PlayButton />
                    </AspectRatioContents>
                  </AspectRatio>
                </VideoThumb>
                <DescriptionContainer>
                  <VideoTitle>{videoTitle}</VideoTitle>
                  <Duration>{duration}</Duration>
                </DescriptionContainer>
              </VideoContainer>
              {/* <ModalWrapper>
                <ModalVideo
                  channel='vimeo'
                  isOpen={isModalOpen}
                  videoId={video && getVimeoID(video)}
                  onClose={() => setIsModalOpen(false)}
                  ratio={'16:9'}
                  vimeo={{
                    portrait: false,
                    title: false,
                    byline: false,
                    color: 'FED136',
                    autoplay: true,
                    transparent: true,
                  }}
                />
              </ModalWrapper> */}
              <VideoModal
                isModalOpen={isModalOpen}
                video={tempVideo}
                onClose={() => setIsModalOpen(false)}
              />
            </>
          ),
          statistic: (
            <StatContainer>
              {/* <Stat as={'h3'}>{statistic}</Stat> */}
              <Stat as={'h3'}>
                {brokenStatistic && brokenStatistic.pre}
                <AnimatedInteger
                  endNum={brokenStatistic && brokenStatistic.integer}
                />
                {brokenStatistic && brokenStatistic.post}
              </Stat>
              <StatDesc>{description}</StatDesc>
            </StatContainer>
          ),
          employee: (
            <ContactCard>
              <HeadshotWrap>
                {employee && (
                  <Headshot
                    image={employee.headshot.asset.gatsbyImageData}
                    alt={`${employee.firstName} ${employee.lastName}`}
                  />
                )}
              </HeadshotWrap>
              <Name>{`${employee && employee.firstName} ${
                employee && employee.lastName
              }`}</Name>
              <Title>{employee && employee.role}</Title>
              <a href={`tel:${employee && employee.phoneNumber}`}>
                <ContactDetailWrap style={{ marginBottom: '8px' }}>
                  <ContactIcon>
                    <svg
                      viewBox='0 0 20 21'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M18.3333 14.9529V17.4529C18.3343 17.685 18.2867 17.9148 18.1937 18.1274C18.1008 18.34 17.9644 18.5309 17.7934 18.6878C17.6224 18.8447 17.4205 18.9642 17.2006 19.0385C16.9808 19.1129 16.7478 19.1405 16.5167 19.1196C13.9523 18.841 11.4892 17.9647 9.32498 16.5613C7.31151 15.2818 5.60443 13.5748 4.32499 11.5613C2.91663 9.38728 2.04019 6.91211 1.76665 4.33628C1.74583 4.10583 1.77321 3.87358 1.84707 3.6543C1.92092 3.43502 2.03963 3.23352 2.19562 3.06263C2.35162 2.89174 2.54149 2.7552 2.75314 2.66171C2.9648 2.56822 3.1936 2.51983 3.42499 2.51961H5.92499C6.32941 2.51563 6.72148 2.65884 7.02812 2.92256C7.33476 3.18627 7.53505 3.55248 7.59165 3.95295C7.69717 4.753 7.89286 5.53855 8.17499 6.29461C8.2871 6.59288 8.31137 6.91704 8.24491 7.22868C8.17844 7.54032 8.02404 7.82637 7.79998 8.05295L6.74165 9.11128C7.92795 11.1976 9.65536 12.925 11.7417 14.1113L12.8 13.0529C13.0266 12.8289 13.3126 12.6745 13.6243 12.608C13.9359 12.5416 14.26 12.5658 14.5583 12.6779C15.3144 12.9601 16.0999 13.1558 16.9 13.2613C17.3048 13.3184 17.6745 13.5223 17.9388 13.8342C18.203 14.1461 18.3435 14.5443 18.3333 14.9529Z'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </ContactIcon>
                  <ContactDetail>
                    {employee && employee.phoneNumber}
                  </ContactDetail>
                </ContactDetailWrap>
              </a>
              <a href={`mailto:${employee && employee.email}`}>
                <ContactDetailWrap>
                  <ContactIcon>
                    <svg
                      viewBox='0 0 20 21'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M18.3333 5.85319C18.3333 4.93652 17.5833 4.18652 16.6667 4.18652H3.33332C2.41666 4.18652 1.66666 4.93652 1.66666 5.85319M18.3333 5.85319V15.8532C18.3333 16.7699 17.5833 17.5199 16.6667 17.5199H3.33332C2.41666 17.5199 1.66666 16.7699 1.66666 15.8532V5.85319M18.3333 5.85319L9.99999 11.6865L1.66666 5.85319'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </ContactIcon>
                  <ContactDetail>{employee && employee.email}</ContactDetail>
                </ContactDetailWrap>
              </a>
            </ContactCard>
          ),
        }[content]
      }
    </>
  );
};

SubPoint.propTypes = {
  type: PropTypes.oneOf(['text', 'video', 'statistic', 'employee']),
};

SubPoint.defaultProps = {
  type: 'text',
};

export default SubPoint;
